<template>
  <b-card class="login-body mb-5">
    <div v-if="!error">
      <h4>{{ $t('SCANQR.REDIRECTING') }}</h4>
    </div>
    <div v-else>
      <p v-if="error == 'NO_TOKEN'">{{ $t('SCANQR.NO_TOKEN') }}</p>
      <p v-if="error == 'NO_MEMBER'">{{ $t('SCANQR.NO_MEMBER') }}</p>
    </div>
  </b-card>
</template>
  
<style lang="scss" scoped>
.underline-on-hover:hover {
  text-decoration: underline !important;
}

.login-body {
  max-width: 500px;
  width: 100%;

  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;

    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;

      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
}
</style>
  
<script>

import dayjs from 'dayjs';
import axios from 'axios';

export default {
  name: 'ScanQrMember',

  components: {
    
  },

  data() {
    return {
      error: null,
      accept_terms: [],
      selected_term: {}
    };
  },

  async mounted() {

    const token = this.$route.params.token;

    if (!token) {
      this.error = 'NO_TOKEN';
      return;
    }

    this.get_member_by_token(token);
  },

  computed: {
    
  },

  methods: {

    async get_member_by_token(token) {
      try {
        const res = await axios.get(`/member/qr-hash/${token}`);

        if (res.status === 200) {
          location.href = `/ml-member-editor/${res.data.member_id}`;

          return;
        }
      }
      catch (err) {
        console.error('error finding member qr_hash', err);
      }

      this.error = 'NO_MEMBER';
    },

    term_checked(term) {
    },

    show_term(term) {
      this.selected_term = term;
      this.$refs['show-term'].show();
    },

    confirm_terms() {
      this.$refs['accept-terms'].hide();
      this.accept_terms.forEach(async (term) => {
        if (term.accepted) {
          const res = await axios.post(`/company_term_user`, { company_term_id: term.id });
        }
      });

      updateAcceptedTerms(); // this is needed in case accepted terms changed so the store has correct data

      this.continue_forward();
    },

    continue_forward() {

      const currentAccess = this.$store.getters['currentAccess'];

      let ok_page = 'ml-dashboard';
      let found = false;

      if (!(ok_page in currentAccess)) {

        for (const k in currentAccess) {
          ok_page = k;
          found = true;
          break;
        }

      }
      else {
        found = true;
      }

      console.log('ok_page', ok_page);
      console.log('found', found);

      if (!found) {
        this.error = 'no_access';
      }
      else {
        localStorage.setItem('forwarding', undefined);
        this.$router.push({ name: ok_page });
      }
    },
  }
};
</script>
  